/* ==========================================================================
   Theme - standard form styling
   ========================================================================== */

.wpforms-clear:before {
	content: " ";
	display: table;
}

.wpforms-clear:after {
	clear: both;
	content: " ";
	display: table;
}

/* Basic Field properties
----------------------------------------------------------------------------- */

/* Field sizes - medium */
div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
	max-width: 60%;
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-medium {
	height: 120px;
}

/* Field sizes - small */
div.wpforms-container-full .wpforms-form input.wpforms-field-small,
div.wpforms-container-full .wpforms-form select.wpforms-field-small,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-small {
	max-width: 25%;
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-small {
	height: 70px;
}

/* Field sizes - large */
div.wpforms-container-full .wpforms-form input.wpforms-field-large,
div.wpforms-container-full .wpforms-form select.wpforms-field-large,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-large {
	max-width: 100%;
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
	height: 220px;
}

/* Field container */
div.wpforms-container-full .wpforms-form .wpforms-field {
	padding: 10px 0;
	clear: both;
}

/* Field Description */
div.wpforms-container-full .wpforms-form .wpforms-field-description,
div.wpforms-container-full .wpforms-form .wpforms-field-limit-text {
	font-size: 13px;
	line-height: 1.3;
	margin: 8px 0 0 0;
	word-break: break-word;
	word-wrap: break-word;
}

div.wpforms-container-full .wpforms-form .wpforms-field-description.wpforms-disclaimer-description {
	background-color: #fff;
	border: 1px solid #ddd;
	color: #444;
	padding: 15px 15px 0;
	margin-top: 15px;
	height: 125px;
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: 12px
}

div.wpforms-container-full .wpforms-form .wpforms-field-description.wpforms-disclaimer-description p {
	color: #444;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 15px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-description-before,
div.wpforms-container-full .wpforms-form .wpforms-field-description.before {
	margin: 0 0 8px 0;
}

/* Labels and sub-labels */
div.wpforms-container-full .wpforms-form .wpforms-field-label {
	display: block;
	font-weight: 700;
	font-size: 16px;
	float: none;
	line-height: 1.3;
	margin: 0 0 4px 0;
	padding: 0;
	word-break: break-word;
	word-wrap: break-word;
}

div.wpforms-container-full .wpforms-form .wpforms-field-sublabel {
	display: block;
	font-size: 13px;
	float: none;
	font-weight: 400;
	line-height: 1.3;
	margin: 4px 0 0;
	padding: 0;
}

div.wpforms-container-full .wpforms-form .wpforms-field-sublabel.before {
	margin: 0 0 4px 0;
}

div.wpforms-container-full .wpforms-form .wpforms-field-label-inline {
	display: inline;
	vertical-align: baseline;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
	word-break: break-word;
}

div.wpforms-container-full .wpforms-form .wpforms-field-label.wpforms-label-hide,
div.wpforms-container-full .wpforms-form .wpforms-field-sublabel.wpforms-sublabel-hide {
	position: absolute;
	top: -99999px;
	left: -99999px;
}

div.wpforms-container-full .wpforms-form .wpforms-required-label {
	color: #ff0000;
	font-weight: 400;
}


/* Rows (multi-line fields: address, credit card, etc)
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-row {
	margin-bottom: 8px;
	position: relative;
}

div.wpforms-container-full .wpforms-form .wpforms-field .wpforms-field-row:last-of-type {
	margin-bottom: 0;
}

/* Clear each row */
div.wpforms-container-full .wpforms-form .wpforms-field-row:before {
	content: " ";
	display: table;
}

div.wpforms-container-full .wpforms-form .wpforms-field-row:after {
	clear: both;
	content: " ";
	display: table;
}

div.wpforms-container-full .wpforms-form .wpforms-field-address .wpforms-one-half:only-child {
	margin-left: 0;
}

/* Columns
----------------------------------------------------------------------------- */

/* User column classes  */
div.wpforms-container-full .wpforms-form .wpforms-five-sixths,
div.wpforms-container-full .wpforms-form .wpforms-four-sixths,
div.wpforms-container-full .wpforms-form .wpforms-four-fifths,
div.wpforms-container-full .wpforms-form .wpforms-one-fifth,
div.wpforms-container-full .wpforms-form .wpforms-one-fourth,
div.wpforms-container-full .wpforms-form .wpforms-one-half,
div.wpforms-container-full .wpforms-form .wpforms-one-sixth,
div.wpforms-container-full .wpforms-form .wpforms-one-third,
div.wpforms-container-full .wpforms-form .wpforms-three-fourths,
div.wpforms-container-full .wpforms-form .wpforms-three-fifths,
div.wpforms-container-full .wpforms-form .wpforms-three-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-fourths,
div.wpforms-container-full .wpforms-form .wpforms-two-fifths,
div.wpforms-container-full .wpforms-form .wpforms-two-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-thirds {
	float: left;
	margin-left: 4%;
	clear: none;
}

div.wpforms-container-full .wpforms-form .wpforms-one-half,
div.wpforms-container-full .wpforms-form .wpforms-three-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-fourths {
	width: 48%;
}

div.wpforms-container-full .wpforms-form .wpforms-one-third,
div.wpforms-container-full .wpforms-form .wpforms-two-sixths {
	width: 30.6666666667%;
}

div.wpforms-container-full .wpforms-form .wpforms-four-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-thirds {
	width: 65.3333333333%;
}

div.wpforms-container-full .wpforms-form .wpforms-one-fourth {
	width: 22%;
}

div.wpforms-container-full .wpforms-form .wpforms-three-fourths {
	width: 74%;
}

div.wpforms-container-full .wpforms-form .wpforms-one-fifth {
	width: 16.8%;
}

div.wpforms-container-full .wpforms-form .wpforms-two-fifths {
	width: 37.6%;
}

div.wpforms-container-full .wpforms-form .wpforms-three-fifths {
	width: 58.4%;
}

div.wpforms-container-full .wpforms-form .wpforms-four-fifths {
	width: 79.2%;
}

div.wpforms-container-full .wpforms-form .wpforms-one-sixth {
	width: 13.3333333333%;
}

div.wpforms-container-full .wpforms-form .wpforms-five-sixths {
	width: 82.6666666667%;
}

div.wpforms-container-full .wpforms-form .wpforms-first {
	clear: both !important;
	margin-left: 0 !important;
}

/* User list column classes  */
div.wpforms-container-full .wpforms-form .wpforms-checkbox-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-list-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-checkbox-3-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-3-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-list-3-columns ul {
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

div.wpforms-container-full .wpforms-form .wpforms-checkbox-2-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-2-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-list-2-columns ul li {
	width: 50%;
	display: block;
	padding-right: 26px !important;
}

div.wpforms-container-full .wpforms-form .wpforms-checkbox-3-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-3-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-list-3-columns ul li {
	width: 33.3333%;
	display: block;
	padding-right: 26px !important;
}

div.wpforms-container-full .wpforms-form .wpforms-list-inline ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 20px !important;
}

/* Legacy, for BC */
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-first-half {
	float: left;
	width: 48%;
	clear: both;
}
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-last-half {
	float: right;
	width: 48%;
	clear: none;
}
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-first-third {
	float: left;
	width: 30.666666667%;
	clear: both;
}
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-middle-third  {
	float: left;
	width: 30.666666667%;
	margin-left: 4%;
	clear: none;
}
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-last-third {
	float: right;
	width: 30.666666667%;
	clear: none;
}
div.wpforms-container-full .wpforms-form div.wpforms-last {
	float: right !important;
	margin-right: 0 !important;
	clear: none;
}


/* Preset Layouts
----------------------------------------------------------------------------- */

/* Single line */
div.wpforms-container-full.inline-fields {
	overflow: hidden;
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field-container  {
	display: table;
	width: calc(100% - 160px);
	float: left;
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field  {
	display: table-cell;
	padding-right: 2%;
	vertical-align: top;
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {
	float: right;
	width: 160px;
	clear: none;
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit {
	display: block;
	width: 100%;
}

div.wpforms-container-full.inline-fields .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full.inline-fields .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field-row.wpforms-field-medium {
	max-width: 100%;
}


/* Set Styles
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form input[type=date],
div.wpforms-container-full .wpforms-form input[type=datetime],
div.wpforms-container-full .wpforms-form input[type=datetime-local],
div.wpforms-container-full .wpforms-form input[type=email],
div.wpforms-container-full .wpforms-form input[type=month],
div.wpforms-container-full .wpforms-form input[type=number],
div.wpforms-container-full .wpforms-form input[type=password],
div.wpforms-container-full .wpforms-form input[type=range],
div.wpforms-container-full .wpforms-form input[type=search],
div.wpforms-container-full .wpforms-form input[type=tel],
div.wpforms-container-full .wpforms-form input[type=text],
div.wpforms-container-full .wpforms-form input[type=time],
div.wpforms-container-full .wpforms-form input[type=url],
div.wpforms-container-full .wpforms-form input[type=week],
div.wpforms-container-full .wpforms-form select,
div.wpforms-container-full .wpforms-form textarea {
	background-color: #fff;
	box-sizing: border-box;
	border-radius: 2px;
	color: #333;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	display: block;
	float: none;
	font-size: 16px;
	font-family: inherit;
	border: 1px solid #ccc;
	padding: 6px 10px;
	height: 38px;
	width: 100%;
	line-height: 1.3;
}

div.wpforms-container-full .wpforms-form input[type=checkbox],
div.wpforms-container-full .wpforms-form input[type=radio] {
	border: 1px solid #ccc;
	background-color: #fff;
	width: 14px;
	height: 14px;
	margin: 0 10px 0 3px;
	display: inline-block;
	vertical-align: baseline;
}

div.wpforms-container-full .wpforms-form input[type=radio] {
	border-radius: 50%;
}

div.wpforms-container-full .wpforms-form select {
	max-width: 100%;
	text-transform: none;
	white-space: nowrap;
}

div.wpforms-container-full .wpforms-form select[multiple] {
	height: auto;
	overflow-y: scroll;
	background-image: none;
}

div.wpforms-container-full .wpforms-form input[type=submit],
div.wpforms-container-full .wpforms-form button[type=submit],
div.wpforms-container-full .wpforms-form .wpforms-page-button {
	background-color: #eee;
	border: 1px solid #ddd;
	color: #333;
	font-size: 1em;
	padding: 10px 15px;
}

div.wpforms-container-full .wpforms-form .wpforms-page-button {
	font-size: 0.9em;
	font-weight: 400;
	margin: 0 5px;
	min-width: 90px;
	text-align: center;
}

div.wpforms-container-full .wpforms-form input[type=submit]:hover,
div.wpforms-container-full .wpforms-form input[type=submit]:focus,
div.wpforms-container-full .wpforms-form input[type=submit]:active,
div.wpforms-container-full .wpforms-form button[type=submit]:hover,
div.wpforms-container-full .wpforms-form button[type=submit]:focus,
div.wpforms-container-full .wpforms-form button[type=submit]:active,
div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
div.wpforms-container-full .wpforms-form .wpforms-page-button:active,
div.wpforms-container-full .wpforms-form .wpforms-page-button:focus {
	background-color: #ddd;
	border: 1px solid #ccc;
	cursor: pointer;
}

div.wpforms-container-full .wpforms-form input[type=submit]:disabled,
div.wpforms-container-full .wpforms-form button[type=submit]:disabled,
div.wpforms-container-full .wpforms-form .wpforms-page-button:disabled {
	background-color: #eee;
	border: 1px solid #ddd;
	cursor: default;
	opacity: 0.5;
}

div.wpforms-container-full .wpforms-form input:focus,
div.wpforms-container-full .wpforms-form textarea:focus,
div.wpforms-container-full .wpforms-form select:focus,
div.wpforms-container-full .wpforms-form .is-focused .choices__inner,
div.wpforms-container-full .wpforms-form .is-open .choices__inner,
div.wpforms-container-full .wpforms-form .is-open .choices__list--dropdown {
	border: 1px solid #999;
	box-shadow: none;
}

div.wpforms-container-full .wpforms-form input:disabled,
div.wpforms-container-full .wpforms-form textarea:disabled,
div.wpforms-container-full .wpforms-form select:disabled {
	background-color: #f9f9f9;
	border-color: #ddd;
	color: #999;
	cursor: not-allowed;
}


/* Errors, Warnings, etc
----------------------------------------------------------------------------- */
div.wpforms-container-full .wpforms-form .wpforms-error-container,
div.wpforms-container-full .wpforms-form noscript.wpforms-error-noscript {
	color: #990000;
}

div.wpforms-container-full .wpforms-form label.wpforms-error {
	display: block;
	color: #990000;
	font-size: 12px;
	float: none;
	cursor: default;
}

div.wpforms-container-full .wpforms-form .wpforms-field input.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field input.user-invalid,
div.wpforms-container-full .wpforms-form .wpforms-field textarea.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field textarea.user-invalid,
div.wpforms-container-full .wpforms-form .wpforms-field select.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field select.user-invalid,
div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-has-error .choices__inner {
	border: 1px solid #cc0000;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration label.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-code label.wpforms-error {
	display: none !important;
}

/* Confirmation
----------------------------------------------------------------------------- */

/*
 * Note: The second selector for the following two rules is needed to override the rule with
 * the selector: `div.wpforms-container-full, div.wpforms-container-full .wpforms-form *`.
 * The underlying cause is that the .wpforms-confirmation-container-full is not expected
 * to be located inside of the .wpforms-confirmation-container-full element, since on non-AMP
 * pages the confirmation message is never displayed on the same page as the form.
 */
.wpforms-confirmation-container-full,
div[submit-success] > .wpforms-confirmation-container-full {
	color: #333;
	margin: 0 auto 24px;
	padding: 15px 15px;
	overflow-wrap: break-word;
}

.wpforms-confirmation-container-full,
div[submit-success] > .wpforms-confirmation-container-full:not(.wpforms-redirection-message) {
	background: #e0ffc7;
	border: 1px solid #b4d39b;
	box-sizing: border-box;
}

.wpforms-confirmation-container-full p:last-of-type,
div[submit-success] > .wpforms-confirmation-container-full p:last-of-type {
	margin: 0;
}

/*
 * Hide the form fields upon successful submission. This may not be the best approach.
 * Perhaps more robust: .wpforms-form.amp-form-submit-success > *:not([submit-success]) { display:none }
 */
.amp-form-submit-success .wpforms-field-container,
.amp-form-submit-success .wpforms-submit-container {
	display: none;
}


/* reCAPTCHA Area
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
	padding: 10px 0 20px 0;
	clear: both;

	// Fix for invisible captcha state see #6130.
	&.wpforms-is-turnstile {

		&-invisible {
			padding: 0;
		}

		.g-recaptcha {
			line-height: 0;
			font-size: 0;

			iframe {
				position: relative !important;
			}
		}
	}
}

div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container iframe {
	width: 100%;
	max-width: 100%;
}


/* Form Header area
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-title {
	font-size: 26px;
	margin: 0 0 10px 0;
}

div.wpforms-container-full .wpforms-form .wpforms-description {
	margin: 0 0 10px 0;
}


/* Form Footer area
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-submit-container {
	padding: 10px 0 0 0;
	clear: both;
	position: relative;
}

div.wpforms-container-full .wpforms-form button[type=submit] {
}

div.wpforms-container-full .wpforms-form .wpforms-submit-spinner {
	margin-left: 0.5em;
	display: inline-block;
	vertical-align: middle;
	max-width: 26px;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-center {
	text-align: center;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-left {
	text-align: left;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-left .wpforms-page-button {
	margin: 0 10px 0 0;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-right {
	text-align: right;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-right .wpforms-page-button {
	margin: 0 0 0 10px;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-split .wpforms-page-prev {
	float: left;
	margin: 0;
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-split .wpforms-page-next {
	float: right;
	margin: 0;
}


/* Credit Card field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-number {
	margin-right: 100px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-code {
	position: absolute;
	right: 0;
	top: 0;
	width: 90px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-name {
	margin-right: 170px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration {
	position: absolute;
	right: 0;
	top: 0;
	width: 160px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration select {
	width: 45%;
	float: left;
	display: block;
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration span {
	float: left;
	width: 10%;
	text-align: center;
	line-height: 38px;
}

/* Number slider field
----------------------------------------------------------------------------- */
div.wpforms-container-full .wpforms-form .wpforms-field-number-slider input[type=range] {
	-webkit-appearance: none;
	appearance: none;
	height: 10px;
	background: #fff;
	border: 1px solid #CCC;
	border-radius: 5px;
	outline: none;
	padding: 0;
	margin: 10px 0 5px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-number-slider input[type=range]::-ms-track {
	color: transparent;
}

div.wpforms-container-full .wpforms-form .wpforms-field-number-slider input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	width: 17px;
	height: 17px;
	background: #B5B5B5;
	cursor: pointer;
	border-radius: 100%;
}

div.wpforms-container-full .wpforms-form .wpforms-field-number-slider input[type=range]::-moz-range-thumb {
	width: 17px;
	height: 17px;
	background: #B5B5B5;
	cursor: pointer;
	border-radius: 100%;
}

div.wpforms-container-full .wpforms-form .wpforms-field-number-slider input[type=range]::-ms-thumb {
	width: 17px;
	height: 17px;
	background: #B5B5B5;
	cursor: pointer;
	border-radius: 100%;
}

div.wpforms-container-full .wpforms-form .wpforms-field-number-slider .wpforms-field-number-slider-hint {
	font-size: 13px;
}

/* Stripe Credit Card field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-stripe-credit-card-cardnumber,
div.wpforms-container-full .wpforms-form input.wpforms-stripe-credit-card-hidden-input {
	background-color: #fff;
	box-sizing: border-box;
	border-radius: 2px;
	color: #333;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	display: block;
	float: none;
	font-size: 16px;
	border: 1px solid #ccc;
	padding: 8px 10px;
	height: 38px;
	width: 100%;
	line-height: 1.3
}

div.wpforms-container-full .wpforms-form .wpforms-stripe-element-invalid {
	border: 1px solid #cc0000!important;
}


/* HTML field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-html ul,
div.wpforms-container-full .wpforms-form .wpforms-field-html ol {
	margin: 0 0 20px !important;
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ul li,
div.wpforms-container-full .wpforms-form .wpforms-field-html ol li {
	margin: 0 0 5px 0 !important;
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ul li {
	list-style: disc !important;
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ol li {
	list-style: decimal !important;
}

div.wpforms-container-full .wpforms-form .wpforms-field-html li > ul,
div.wpforms-container-full .wpforms-form .wpforms-field-html li > ol {
	margin: 6px 0 0 20px !important;
}


/* Date/time field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-field-date-time-date-sep {
	display: inline-block;
	padding: 0 5px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-year,
div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-day,
div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-month {
	display: inline-block;
	width: auto;
}

div.wpforms-container-full .wpforms-datepicker-wrap {
	position: relative;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-datepicker-clear {
	position: absolute;
	background-image: url(../images/times-solid-white.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #cccccc;
	background-size: 8px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	display: block;
	border-radius: 50%;
	right: 10px;
	top: 50%;
	margin-top: -8px;
	transition: all 0.3s;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-datepicker-clear:hover {
	background-color: red;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-field-small + .wpforms-datepicker-clear {
	right: calc( 75% + 10px );
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-field-medium + .wpforms-datepicker-clear {
	right: calc( 40% + 10px );
}

/* Custom Captcha field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-captcha-math input {
	display: inline-block;
	width: 70px;
	vertical-align: inherit;
	margin: 0 0 0 5px;
}

div.wpforms-container-full .wpforms-form .wpforms-captcha-equation {
	font-size: 16px;
}

div.wpforms-container-full .wpforms-form .wpforms-captcha-question {
	margin: 0 0 4px 0;
}

/* Rating field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-rating-item {
	padding-right: 6px;
}

div.wpforms-container-full .wpforms-form .wpforms-field-rating svg {
	cursor: pointer;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	opacity: 0.60;
}

div.wpforms-container-full .wpforms-form .wpforms-field-rating-item.selected svg,
div.wpforms-container-full .wpforms-form .wpforms-field-rating-item.hover svg,
div.wpforms-container-full .wpforms-form .wpforms-field-rating-item input:focus + svg {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 1;
}

/* File upload field
----------------------------------------------------------------------------- */
div.wpforms-field-file-upload .wpforms-hide{
	display: none !important;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern {
	border: 2px dashed rgba(0, 0, 0, 0.1);
	background: rgba(0, 0, 0, 0.02);
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern.wpforms-with-files{
	padding: 5px;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern svg{
	height: 50px;
	width: 50px;
	color: #e27730;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-modern-title{
	font-weight: bold;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-modern-hint{
	color: rgba(0, 0, 0, 0.1);
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern.wpforms-highlighted{
	border-color: #e27730;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-text{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block{
	width: 100px;
	height: 100px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	background-size: cover !important;
	margin: 5px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block:hover .wpforms-overlay{
	opacity: 1;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-close{
	position: absolute;
	right: 5px;
	top: 5px;
	cursor: pointer;
	display: block;
	width: 18px;
	height: 18px;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-title{
	position: absolute;
	font-size: 12px;
	color: #fff;
	top: 25px;
	bottom: 10px;
	left: 5px;
	right: 5px;
	overflow-y: auto;
	line-height: 16px;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-close .wpforms-close-left,
div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-close .wpforms-close-right{
	height: 18px;
	width: 1px;
	background: #fff;
	position: absolute;
	display: block;
	z-index: 1;
	right: 9px;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-close .wpforms-close-left{
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-overlay .wpforms-close .wpforms-close-right{
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block svg{
	height: 25px;
	width: 25px;
	color: rgba(0, 0, 0, 0.1);
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern .wpforms-preview-block .wpforms-progress{
	height: 5px;
	width: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #e27730;
	transition: all 0.5s;
}

div.wpforms-field-file-upload .wpforms-file-upload-builder-modern input[type="file"] {
	display: none;
}


/* Image choices
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices label:not(.wpforms-error) {
	cursor: pointer;
	position: relative;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices label input {
	top: 50%;
}

/* Modern style */
div.wpforms-container-full .wpforms-form .wpforms-list-inline ul.wpforms-image-choices-modern li {
	margin: 5px 5px 5px 5px !important;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern img {
	display: inline-block;
	margin: 0 auto;
	max-width: 100%;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern label:not(.wpforms-error) {
	background-color: #fff;
	display: block;
	margin: 0 auto;
	border: 1px solid #fff;
	border-radius: 3px;
	padding: 20px 20px 18px 20px;
	transition: all 0.5s;
	text-align: center;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern label:not(.wpforms-error):hover,
div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern label:not(.wpforms-error):focus {
	border:1px solid #ddd;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected label {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-image:after {
	content: "\2714";
	font-size: 22px;
	line-height: 32px;
	color: #fff;
	background: green;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -16px 0 0 -16px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	transition: all 0.5s;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected .wpforms-image-choices-image:after {
	opacity: 1;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-image {
	display: block;
	position: relative;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected .wpforms-image-choices-label {
	font-weight: 700;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-label {
	display: block;
	margin-top: 12px;
}

/* Classic */
div.wpforms-container-full .wpforms-form .wpforms-list-inline ul.wpforms-image-choices-classic li {
	margin: 0 10px 10px 0 !important;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic img {
	display: inline-block;
	margin: 0 auto;
	max-width: 100%;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic label:not(.wpforms-error) {
	background-color: #fff;
	display: block;
	margin: 0 auto;
	border: 2px solid #fff;
	padding: 10px;
	text-align: center;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic label:not(.wpforms-error):hover,
div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic label:not(.wpforms-error):focus {
	border-color: #ddd;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-image-choices-image {
	display: block;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-selected label {
	border-color: #666 !important;
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-image-choices-label {
	display: block;
	margin-top: 8px;
}

/* Icon choices
----------------------------------------------------------------------------- */

/* Base styles */
div.wpforms-container-full .wpforms-form {

	ul.wpforms-icon-choices,
	ul.wpforms-icon-choices * {
		box-sizing: border-box;
	}

	// Base list container styles, applies to 1 column layout option too.
	ul.wpforms-icon-choices {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 !important;
		margin: 12px 0 -20px 0 !important; // Compensate for right-most and bottom-most items margin.

		// Descriptions and errors after the list should be spaced out consistently.
		& + .wpforms-field-description,
		& + .wpforms-error {
			margin-top: 15px;
		}

		// Base list item styles, applies to 1 column layout option too.
		li {
			min-width: 120px;
			padding-right: 0 !important;
			margin: 0 0 20px 0 !important; // Faux gaps.
		}

		label {
			position: relative;
			display: block;
			margin: 0;
			cursor: pointer;
		}

		.wpforms-icon-choices-icon {
			display: block;
		}

		svg {
			margin: 0 auto;
			fill: var(--wpforms-icon-choices-color);
		}

		&.wpforms-icon-choices-none {

			svg {
				margin: 0;
			}
		}

		&.wpforms-icon-choices-default,
		&.wpforms-icon-choices-modern {

			li {
				margin: 0 0 22px 0 !important; // Faux gaps.
			}
		}

		/* Style: Default */
		&.wpforms-icon-choices-default {

			label {
				text-align: center;

				&:focus-within {

					.wpforms-icon-choices-icon {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}
				}
			}

			.wpforms-icon-choices-icon {
				position: relative;
				z-index: 1;
				margin-bottom: 10px;
				padding: 15px 20px 45px 20px;
				background-color: #ffffff;
				box-shadow: 0 0 0 1px #cccccc;
				border-radius: 6px;

				&:hover {
					box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
				}

				svg {
					position: relative;
					z-index: 2;
				}
			}

			.wpforms-icon-choices-icon-bg {
				display: block;
				position: absolute;
				z-index: 0;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				border-radius: 6px;
				background-color: #ffffff;
			}

			.wpforms-selected {

				.wpforms-icon-choices-icon {
					background-color: transparent;
					box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					box-sizing: border-box;
				}

				.wpforms-icon-choices-icon-bg {
					background-color: var(--wpforms-icon-choices-color);
					opacity: .1;
				}
			}
		}

		/* Style: Modern */
		&.wpforms-icon-choices-modern {

			li {

				label {
					background-color: #ffffff !important;
					box-shadow: 0 0 0 1px #cccccc;
					border-radius: 6px;
					height: 100%;
					padding: 20px 20px 15px 20px;
					text-align: center;

					&:hover {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}
				}

				&:focus-within {

					label {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}
				}

				&.wpforms-selected {

					label {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color), 0 2px 10px rgba(0, 0, 0, 0.15);
					}
				}

				.wpforms-icon-choices-icon {
					margin-bottom: 10px;
				}
			}
		}

		/* Style: Classic */
		&.wpforms-icon-choices-classic {

			li {

				label {
					background-color: #ffffff !important;
					height: 100%;
					padding: 20px 20px 15px 20px;
					text-align: center;

					&:hover {
						box-shadow: 0 0 0 1px #999999;
					}
				}

				&:focus-within {

					label {
						box-shadow: 0 0 0 1px #999999;
					}
				}

				&.wpforms-selected {

					label {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}
				}

				.wpforms-icon-choices-icon {
					margin-bottom: 10px;
				}
			}
		}
	}

	// Custom controls for radio inputs (Default style).
	.wpforms-field-radio,
	.wpforms-field-payment-multiple {

		ul.wpforms-icon-choices-default {

			li {

				.wpforms-icon-choices-icon:before {
					content: "";
					position: absolute;
					z-index: 2;
					bottom: 15px;
					left: calc(50% - 8px);
					display: block;
					width: 16px;
					height: 16px;
					background-color: #ffffff;
					box-shadow: 0 0 0 1px #cccccc;
					border-radius: 50%;
					margin: 15px auto 0;
				}

				&.wpforms-selected {

					.wpforms-icon-choices-icon:before {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}

					.wpforms-icon-choices-icon:after {
						content: "";
						position: absolute;
						z-index: 2;
						bottom: calc(15px + 4px);
						left: calc(50% - 4px);
						display: block;
						width: 8px;
						height: 8px;
						background-color: var(--wpforms-icon-choices-color);
						border-radius: 50%;
					}
				}
			}
		}
	}

	// Custom controls for checkbox inputs (Default style).
	.wpforms-field-checkbox,
	.wpforms-field-payment-checkbox {

		ul.wpforms-icon-choices-default {

			li {

				.wpforms-icon-choices-icon:before {
					content: "";
					position: absolute;
					z-index: 2;
					bottom: 15px;
					left: calc(50% - 8px);
					display: block;
					width: 16px;
					height: 16px;
					background-color: #ffffff;
					box-shadow: 0 0 0 1px #cccccc;
					border-radius: 3px;
					margin: 15px auto 0;
				}

				&.wpforms-selected {

					.wpforms-icon-choices-icon:before {
						box-shadow: 0 0 0 1px var(--wpforms-icon-choices-color);
					}

					.wpforms-icon-choices-icon:after {
						content: "";
						box-sizing: border-box;
						display: block;
						position: absolute;
						z-index: 2;
						bottom: 23px;
						left: calc(50% - 6px);
						width: 6px;
						height: 10px;
						border-style: solid;
						border-color: var(--wpforms-icon-choices-color);
						border-width: 0 2px 2px 0;
						transform-origin: bottom left;
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	// All other layout options, except one column.
	.wpforms-list-2-columns ul.wpforms-icon-choices,
	.wpforms-list-3-columns ul.wpforms-icon-choices,
	.wpforms-list-inline ul.wpforms-icon-choices {
		flex-direction: row;
		flex-wrap: wrap;

		li {
			margin-right: 20px !important;
		}

		&.wpforms-icon-choices-default,
		&.wpforms-icon-choices-modern {

			li {
				margin-right: 22px !important;
				margin-bottom: 22px !important;
			}
		}
	}

	// Two columns layout.
	.wpforms-list-2-columns ul.wpforms-icon-choices {

		li {
			width: calc( 100% / 2 - 20px / 2 );

			&:nth-child(2n) {
				margin-right: 0 !important;
			}
		}

		&.wpforms-icon-choices-default,
		&.wpforms-icon-choices-modern {

			li {
				width: calc( 100% / 2 - 22px / 2 );
			}
		}
	}

	// Three column layout.
	.wpforms-list-3-columns ul.wpforms-icon-choices {

		li {
			width: calc( 100% / 3 - 20px * 2 / 3 );

			&:nth-child(3n) {
				margin-right: 0 !important;
			}
		}

		&.wpforms-icon-choices-default,
		&.wpforms-icon-choices-modern {

			li {
				width: calc( 100% / 3 - 22px * 2 / 3 );
			}
		}
	}

	// Inline: fluid sizing.
	.wpforms-list-inline ul.wpforms-icon-choices {

		li {
			width: auto;
			max-width: calc( 100% / 4 - 20px );
		}
	}
}

/* Page Indicator themes
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-page-indicator {
	margin: 0 0 20px 0;
	overflow: hidden;
}

/** Circles theme **/
div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles {
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	padding: 15px 10px;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page {
	float: left;
	margin: 0 20px 0 0;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page:last-of-type {
	margin: 0;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page-number {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: inline-block;
	margin: 0 10px 0 0;
	line-height: 40px;
	text-align: center;
	background-color: #ddd;
	color: #666;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .active .wpforms-page-indicator-page-number {
	color: #fff;
}

/* Connector theme */
div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page {
	float: left;
	text-align: center;
	font-size: 16px;
	line-height: 1.2;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-number {
	display: block;
	text-indent: -9999px;
	height: 6px;
	background-color: #ddd;
	margin: 0 0 16px 0;
	position: relative;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-triangle {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 0;
	height: 0;
	margin-left: -5px;
	border-style: solid;
	border-width: 6px 5px 0 5px;
	border-color: transparent transparent transparent transparent;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-title {
	display: inline-block;
	padding: 0 15px;
	font-size: 16px;
}

/* Progress theme */
div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress {
	font-size: 18px;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress .wpforms-page-indicator-page-progress-wrap {
	display: block;
	width: 100%;
	background-color: #ddd;
	height: 18px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	margin: 5px 0 0;
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress .wpforms-page-indicator-page-progress {
	height: 18px;
	position: absolute;
	left: 0;
	top: 0;
}

/* Dropdown field
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-form .wpforms-field-select select > option {
	color: inherit;
}

div.wpforms-container-full .wpforms-form .wpforms-field-select select > option.placeholder,
div.wpforms-container-full .wpforms-form .wpforms-field-select select > option[disabled] {
	color: inherit;
	opacity: 0.5;
}

/* Classic style */
div.wpforms-container-full .wpforms-field.wpforms-field-select-style-classic select {
	padding-left: 6px;

	&[multiple] {
		padding: 0;

		> option {
			padding: 10px;
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

			&.placeholder,
			&[disabled] {
				box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
			}

			&:checked {
				background-color: lightgrey;
			}
		}
	}
}

/* Modern style */
div.wpforms-container-full .wpforms-form .choices {
	font-size: 16px;
	color: #333;
}

div.wpforms-container-full .wpforms-form .choices .choices__list--single {
	font-size: 1em;
	line-height: normal;
}

// Override Choices border-radius on the frontend.
div.wpforms-container-full .wpforms-form .choices.is-open.is-flipped .choices__inner,
div.wpforms-container-full .wpforms-form .choices.is-open .choices__list--dropdown {
	border-radius: 0 0 2px 2px;
}

div.wpforms-container-full .wpforms-form .choices.is-open.is-flipped .choices__list--dropdown,
div.wpforms-container-full .wpforms-form .choices.is-open .choices__inner {
	border-radius: 2px 2px 0 0;
}

div.wpforms-container-full .wpforms-form .choices {
	.choices__inner {
		min-height: 38px;
		border-radius: 2px;
	}
}

div.wpforms-container-full .wpforms-form .choices input.choices__input {
	display: inline-block;
	height: auto;
	line-height: 1.3;
}

div.wpforms-container-full .wpforms-form .choices ::-webkit-input-placeholder {
	color: inherit;
	opacity: 0.5;
}

div.wpforms-container-full .wpforms-form .choices ::-moz-placeholder {
	color: inherit;
	opacity: 0.5;
}

div.wpforms-container-full .wpforms-form .choices :-ms-input-placeholder {
	color: inherit;
	opacity: 0.5;
}

/* Notices
----------------------------------------------------------------------------- */

div.wpforms-container-full .wpforms-notice {
	background-color: #fff;
	border: 1px solid #ddd;
	border-left-width: 12px;
	color: #333;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 30px;
	padding: 20px 36px 20px 26px;
	position: relative;
}

div.wpforms-container-full .wpforms-notice .wpforms-delete {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: rgba(10, 10, 10, 0.2);
	border: none;
	border-radius: 290486px;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin: 0;
	padding: 0;
	outline: none;
	vertical-align: top;
	width: 20px;
	position: absolute;
	right: 10px;
	top: 10px;
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:before,
div.wpforms-container-full .wpforms-notice .wpforms-delete:after {
	background-color: #fff;
	content: "";
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-webkit-transform-origin: center center;
	transform-origin: center center;
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:before {
	height: 2px;
	width: 50%;
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:after {
	height: 50%;
	width: 2px;
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:hover,
div.wpforms-container-full .wpforms-notice .wpforms-delete:focus  {
	background-color: rgba(10, 10, 10, 0.3);
}

div.wpforms-container-full .wpforms-notice a {
	text-decoration: underline;
}

div.wpforms-container-full .wpforms-notice p {
	margin: 0 0 20px 0;
}

div.wpforms-container-full .wpforms-notice p:last-of-type {
	margin-bottom: 0;
}

div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-info {
	border-color: #3273dc
}

div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-success {
	border-color: #23d160
}

div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-warning {
	border-color: #ffdd57
}

div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-error {
	border-color: #ff3860
}

div.wpforms-container-full .wpforms-notice .wpforms-notice-actions {
	margin-top: 20px;
}

div.wpforms-container-full .wpforms-notice .wpforms-notice-action {
	border: 2px solid;
	margin-right: 20px;
	padding: 5px;
	text-decoration: none;
}
div.wpforms-container-full .wpforms-notice .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice .wpforms-notice-action:active {
	color: #fff;
}

div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:active {
	background-color: #3273dc;
}

div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:active {
	background-color: #23d160;
}

div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:active {
	background-color: #ffdd57;
	color: inherit;
}

div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:active {
	background-color: #ff3860;
}


div.wpforms-container-full {
	.wpforms-form {
		textarea.wpforms-field-small.wp-editor-area {
			height: 100px;
		}

		textarea.wpforms-field-medium.wp-editor-area {
			height: 250px;
		}

		textarea.wpforms-field-large.wp-editor-area {
			height: 400px;
		}
	}
}

/* Preview notice.
----------------------------------------------------------------------------- */

.wpforms-preview-notice-links {
	line-height: 2.4;
}

/* Misc
----------------------------------------------------------------------------- */

div.wpforms-container-full {
	margin: 24px auto;
}

div.wpforms-container-full .wpforms-form h3 {
	font-size: 24px;
}

/* Honeypot Area */
div.wpforms-container-full .wpforms-form .wpforms-field-hp {
	display: none !important;
	position: absolute !important;
	left: -9000px !important;
}

div.wpforms-container-full .wpforms-form .wpforms-field-hidden {
	display: none;
	padding: 0;
}

div.wpforms-container-full .wpforms-form .wpforms-screen-reader-element {
	position: absolute !important;
	clip: rect(0, 0, 0, 0) !important;
	height: 1px !important;
	width: 1px !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
	word-wrap: normal !important;
}

/* Limit options */
div.wpforms-container-full .wpforms-form .wpforms-limit-text{
	font-size: 13px;
	display: block;
}

/* RTL support
----------------------------------------------------------------------------- */

/* Phone US format */
body.rtl .wpforms-field-phone input[type=tel] {
	direction: ltr;
	unicode-bidi: embed;
	text-align: right;
}

body.rtl .wpforms-container-full .wpforms-form .wpforms-first {
	float: right;
}

body.rtl .wpforms-container-full .wpforms-form .wpforms-first + .wpforms-one-half {
	margin-right: 4%;
	margin-left: 0;
}

/* Un-reset styles for form error container */
div.wpforms-container-full .wpforms-form .wpforms-error-container {
	ul li {
		list-style: inside !important;
	}

	ol li {
		list-style: inside decimal !important;
	}

	a {
		text-decoration: underline !important;
	}

	del {
		text-decoration: line-through !important;
	}

	blockquote {
		padding-left: 20px;
		border-left: 4px solid;
		font-style: italic;
	}
}
