// Small devices.
@media only screen and (max-width: 600px) {
	div.wpforms-container-full {

		// Preset layout - single line.
		&.inline-fields .wpforms-form .wpforms-field-container,
		&.inline-fields .wpforms-form .wpforms-field {
			display: block;
			width: 100%;
		}

		&.inline-fields .wpforms-form .wpforms-submit-container {
			width: 100%;
		}

		.wpforms-form {

			.wpforms-page-indicator.connector .wpforms-page-indicator-page-title {
				display: none;
			}

			// Avoid horizontal scroll on mobiles.
			// Skip for phone and modern multiple select field. Otherwise they (dropdowns) will be cropped.
			.wpforms-field:not(.wpforms-field-phone):not(.wpforms-field-select-style-modern) {
				overflow-x: hidden;
			}

			.wpforms-field > * {
				max-width: 100%;
			}

			// Mobile width override.
			.wpforms-mobile-full {
				width: 100%;
				margin-left: 0;
				float: none;
			}

			// Full width for all sizes.
			input.wpforms-field-medium,
			select.wpforms-field-medium,
			.wpforms-field-row.wpforms-field-medium,
			input.wpforms-field-small,
			select.wpforms-field-small,
			.wpforms-field-row.wpforms-field-small,
			input.wpforms-field-large,
			select.wpforms-field-large,
			.wpforms-field-row.wpforms-field-large {
				max-width: 100%;
			}

			// User list column classes.
			.wpforms-checkbox-2-columns,
			.wpforms-multiplechoice-2-columns,
			.wpforms-list-2-columns,
			.wpforms-checkbox-3-columns,
			.wpforms-multiplechoice-3-columns,
			.wpforms-list-3-columns {
				ul li {
					float: none;
					width: 100%
				}
			}

			// Page indicator.
			.wpforms-page-indicator {
				&.circles {
					.wpforms-page-indicator-page {
						margin: 0 10px 0 0;

						&-number {
							width: 30px;
							height: 30px;
							line-height: 30px;
						}
					}
				}
			}

			// Number Slider field.
			.wpforms-field-number-slider input[type=range] {
				margin: 20px 0 15px;

				&::-webkit-slider-thumb {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}
