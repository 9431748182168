// WPForms frontend styles.
//
// Common part for Base and Full styles.
//
// @since 1.7.5

.wpforms-container .wpforms-form {
	%wpforms-base-alert {
		border: 1px solid #cccccc;
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
		padding: 10px 15px;
		font-size: 14px;
		margin: 0 0 10px 0;
	}

	.wpforms-error-alert {
		@extend %wpforms-base-alert;

		color: #b94a48;
		background-color: #f2dede;
		border-color: #eed3d7;
	}
}


/* hCAPTCHA Area
----------------------------------------------------------------------------- */
div[style*="z-index: 2147483647"] {
	div[style*="border-width: 11px"][style*="position: absolute"][style*="pointer-events: none"] {
		border-style: none;
	}
}

/* Cloudflare Turnstile iframe content alignment fix.
----------------------------------------------------------------------------- */
.wpforms-is-turnstile iframe {
	margin-left: -2px !important;
}
